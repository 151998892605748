<template>
  <div>
    <Header/> 
    <div style="padding: 6px;">
        <div class="uni-searchbar__box" :class="{'uni_searchbar':uni_searchbar,'uni_searchbars':uni_searchbars}" @click="search">
            <input type="search" v-model="searchContent" class="uni-searchbar__text-placeholder" @keyup="search_text($event)" placeholder="搜索">
        </div>
        <router-link :to="{path:'/'}">
            <div class="uni-searchbar__cancel" v-if="uni_searchbar__cancel">
                取消
            </div>
        </router-link>
    </div>
    <div style="margin: 8vh 0 0 2vw;clear:both;">
        <div class="hot-search-title">{{hot_search}}</div>
        <div style="margin-top: 10px;"></div>
    </div>
  </div>
</template>

<style>
.vue3-player-video{
    background:#b28858;
}
.uni-searchbar__cancel{
    width:12%;
    padding-left: 10px;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    float:left;
}
::-webkit-input-placeholder{
    color:#999;
    font-size: 13px;
    background:rgb(248, 248, 248);
    text-align:center;
    
}
input{
    height: 100%;
    border: 0;
    width: 100%;
    background: none;
}
.hot-search-title{
    font-family: Helvetica Neue;
    font-weight: 700;
    font-size: 17px;
    text-align: left;
    color: rgba(0,0,0,.52);
}
.uni-searchbar__text-placeholder{
    font-size: 13px;
    color: #999;
    margin-left: 5px;
}
.uni-searchbar__box{
    border-radius: 5px;
    background-color: rgb(248, 248, 248);
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 36px;
    padding: 5px 8px 5px 0;
    border-width: .5px;
    border-style: solid;
    border-color: #fff;
    float:left;
}
.uni_searchbar{
    width:100%;
}
.uni_searchbars{
    width:85%;
}
</style>
<script>
import Header from "../Header";
export default {
  components:{Header},
  data(){
    return {
        uni_searchbar__cancel:false,
        uni_searchbar:true,
        uni_searchbars:false,
        searchContent:'',
        hot_search:'',
    }
  },
  created(){
      let lang_id = this.$store.state.lang_id;
      if(lang_id == 1){
        this.hot_search = this.$zh_ft('hot_search');
      }else{
        this.hot_search = this.$zh_jt('hot_search');
      }
  },
  methods:{
      search(){
        if(this.uni_searchbar){
            this.uni_searchbar__cancel = true;
            this.uni_searchbars = true;
            this.uni_searchbar = false;
        }
      },
      search_text(val){
        //   值為13時 表示按下了搜索鍵
        if(val.keyCode == 13){
            this.$router.push({
                path:'/search_result/index',
                query:{
                    content:this.searchContent,
                },
            });
        }
      }
  }
}
</script>

